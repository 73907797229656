import { useState } from 'react'
import useApi from '../services/api';
import Recaptcha from "react-google-recaptcha";

const initialState = {
  name: '',
  email: '',
  telefone: '',
  sistem: '',
  message: '',
}
export const Contact = (props) => {

  const api = useApi();


  const [{ name, email, telefone, sistem, message }, setState] = useState(initialState)
  const [isVerified, setisVerified] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => {
    window.location.reload();
  }

  const recaptchaLoaded = () => {
    console.log("Captch is reloaded")
  }


  const verifyCaptcha = (response) => {
      if(response)
      {
        setisVerified(true)
      }
    }

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(name, email, telefone, sistem, message)
    let data = {
      name: name,
      email: email,
      telefone: telefone,
      sistem: sistem,
      message: message
  }
    const result = await api.addUser(data)
      .then(
        (result) => {
          console.log(result.text)
          alert("Sua solicitalão foi envaida com sucesso!!")
          clearState()
        },
        (error) => {
          console.log(error.text)
          alert("Por favor, verifique primeiro se você é um ser humano")
        }
      )
  }
  return (
    <div>
      <div id='contact'>
<a href='https://wa.me/message/NQGGKFHXWDMDA1' className='whatsapp' target='_blank'>
<i className='whatsappicon fa fa-whatsapp' ></i>
</a>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>VAMOS CONVERSAR?</h2>
                <p>
                Mande suas dúvidas ou sugestões {isVerified}
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Nome'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='E-mail'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='tel'
                        id='telefone'
                        name='telefone'
                        className='form-control'
                        placeholder='(xx) xxxxx-xxxx'
                        mask='(99) 99999-9999'
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='sistem'
                        name='sistem'
                        className='form-control'
                        placeholder='Qual sistema'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Mensagem'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <Recaptcha
                        /* # Local # sitekey="6Le0TO8cAAAAAKOvQR_ZzckeS0MM3M4l-xtoHJk4" */
                                sitekey="6LfpOhwTAAAAABVnTkGeQEdF3ymDaDUEp-MHtlM2"
                                render="explicit"
                                verifyCallback={verifyCaptcha}
                                onloadCallback={recaptchaLoaded}
                    />
                <button type='submit' className='btn btn-custom btn-lg'>
                  Enviar agora
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>CONTATOS</h3>

            </div>
            <div className='contact-item'>
            <p>
                <span>
                  <i className='fa fa-map-marker'></i> Brasil - Itajaí | Santa Catarina  
                </span>{' '}
                {props.data ? props.data.address : 'loading'}
              </p>
              <p>
                <i className='fa fa-phone'></i> Telefone: {props.data ? props.data.phone : 'loading'}
              </p>
              <p>
              <i className='fa fa-envelope'></i> E-mail: {props.data ? props.data.email : 'loading'}
              </p>
              <p>
              <span>
                  <i></i> LGPD 
                </span>{' '}
                <i className='fa fa-copyright'></i>CDC HOST SISTEMAS
                <br></br>
                <i className='fa fa-envelope'></i> E-mail: lgpd@cdchost.com.br
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'} target='_blank'>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : '/'} target='_blank'>
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.linkedin : '/'} target='_blank'>
                      <i className='fa fa-linkedin'></i>
                    </a>
                  </li>

                  <li>
                    <a href={props.data ? props.data.whatsapp : '/'} target='_blank'>
                      <i className='fa fa-whatsapp'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; CDC HOST SISTEMAS 2024 . Todos os direitos reservados.
          </p>
        </div>
      </div>
    </div>
  )
}
